import { ApiService, setCsrfAndTokenHeader } from "./axiosConfig";

export const setSetting = async ( setting, data ) => {
    try {
      console.log("RP DATA", data);

      await setCsrfAndTokenHeader("post")
      const registerResponse = await ApiService.post('/password-complexity', data);

      console.log("RPRESPONSE", registerResponse);
      return registerResponse

    } catch (error) {
      console.error("ERROR TRY", error);
      return error.response
    }
  };

export const getSetting = async ( setting ) => {
  try {
      await setCsrfAndTokenHeader("get")
      let response = await ApiService.get('/password-complexity');
      
      // response = {
      //   ...response,
      //   data: {
      //     ...response.data,
      //     include_numbers: !!response.data.include_numbers,
      //     include_special_chars: !!response.data.include_special_chars,
      //     include_uppercase: !!response.data.include_uppercase,
      //   }
      // }

      console.log("getSetting", response);

      return response
  } catch (err) {
      // console.log(err);
      console.log("somthing Wrong logout");
  }
};