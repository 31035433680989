import React from 'react';
import { Divider, Typography, Stack } from '@mui/material';

const NamedDivider = ({ text }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{ margin: '20px 0' }}
    >
      <Divider flexItem sx={{ flexGrow: 1 }} />
      <Typography variant="subtitle1" color="textSecondary">
        {text}
      </Typography>
      <Divider flexItem sx={{ flexGrow: 1 }} />
    </Stack>
  );
};


export default NamedDivider;