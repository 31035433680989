import { ApiService, setCsrfAndTokenHeader } from "./axiosConfig";

export const register = async (employee) => {  
    try {
      const data = {
        "name" : employee.name,
        "email" : employee.email,
        "password" : employee.password
      };

      await setCsrfAndTokenHeader("post")
      const registerResponse = await ApiService.post('/register', data);

      return registerResponse

    } catch (error) {
      console.error("ERROR TRY", error);
      return error.response
    }
  };

export const forgot_password = async (employee) => {
    try {
      const data = {
        "email" : employee.email,
      };

      // console.log("FP DATA", data);

      await setCsrfAndTokenHeader("post")
      const registerResponse = await ApiService.post('/forgot-password', data);
      // console.log("FPRESPONSE", registerResponse);
      return registerResponse

    } catch (error) {
      console.error("ERROR TRY", error);
      return error.response
    }
  };

export const reset_password = async (employee) => {
    try {
      const data = {
        "token" : employee.token,
        "email" : employee.email,
        "password" : employee.password,
        "password_confirmation" : employee.password_confirmation,
      };

      // console.log("RP DATA", data);

      await setCsrfAndTokenHeader("post")
      const registerResponse = await ApiService.post('/reset-password', data);

      // console.log("RPRESPONSE", registerResponse);
      return registerResponse

    } catch (error) {
      console.error("ERROR TRY", error);
      return error.response
    }
  };

export const login = async (employee) => {  
    try {
      const data = {
        "email" : employee.email,
        "password" : employee.password
      };

      await setCsrfAndTokenHeader("post")
      const loginResponse = await ApiService.post('/login', data);
      // console.log("RESPONSE", loginResponse);
      return loginResponse

    } catch (error) {
      console.error("ERROR TRY", error);
      return error.response
    }
  };

export const verifyTokenAndGetUser = async (token) => {  
  try {
    const data = {
      "token" : token
    };

    // console.log("DATA", data);

    await setCsrfAndTokenHeader("post")
    const loginResponse = await ApiService.post('/login/token', data);

    // console.log("RESPONSE", loginResponse.data);
    return loginResponse

  } catch (error) {
    console.error("ERROR TRY", error);
    return error.response
  }
};

export const getEmployees = async () => {
  try {
      await setCsrfAndTokenHeader("get");
      const response = await ApiService.get('/employees/');

      // console.log("getEmployees", response);

      return response.data.results;
  } catch (err) {
      // console.log(err);
      console.log("somthing Wrong get Employees");
  }
};

export const logout = async () => {
  try {
      await setCsrfAndTokenHeader("get")
      const response = await ApiService.get('/logout');

      // console.log("logout", response);

      return response
  } catch (err) {
      // console.log(err);
      console.log("somthing Wrong logout");
  }
};