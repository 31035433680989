import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useStateContext } from "../../contexts/ContextProvider";
import { addLeaveType, getLeaveTypes, editLeaveType, deleteLeaveRecord, addLeaveGroup, getLeaveGroups, editLeaveGroup, editAnnualLeaveEntitlement,
        editLinkedAccount, addLeaveBalance, getLeaveBalances, editEmployee, getAccrualRuns, runAccrualRun, getLeavePlannerData, getLeaveSettingsData,
        editPayrollSetting, addPublicHoliday, editPublicHoliday, getMaintenanceMode, toggleMaintenanceMode} from "../../services/leaveService";
import { getEmployees } from '../../services/employeeService';
import { getAvailableBackups, downloadBackup, backupDB, restoreDB, restoreDBFromUpload } from '../../services/filesService';
import CustomDataGrid from '../../components/CustomDataGrid';
import CollapsableCard from '../../components/CollapsableCard';
import Select from 'react-select';
import Save from '@mui/icons-material/Save';
import format from 'date-fns/format';
import CustomSnackBar from '../../components/CustomSnackBar';
import * as XLSX from 'xlsx';



const LeaveSettingsPage = () => {
  let { employee, leaveTaken, setLeaveTaken, leaveTypes, setLeaveTypes, leaveGroups, setLeaveGroups, leaveBalances, setLeaveBalances, 
        employees, setEmployees, accrualRuns, setAccrualRuns, setIsLoading, dbBackups, setDBBackups, publicHolidays, setPublicHolidays,
        setSnackbar, maintenanceMode, setMaintenanceMode, annualLeaveEntitlement, setAnnualLeaveEntitlement, linkedAccounts, setLinkedAccounts,
        parmCodes, setParmCodes, payrollSettings, setPayrollSettings } = useStateContext(); 

  const [importData, setImportData] = useState(null);

  useEffect(() => {
    const fetchLeaveValues = async () => {
      setIsLoading(true);
      const maintenance_mode = await getMaintenanceMode();
      setMaintenanceMode(maintenance_mode);

      //set this from all leave taken if admin?
      const leave_planner_data = await getLeavePlannerData(employee, leaveTaken);
      const db_backups = await getAvailableBackups();
      // const employees = await getEmployees();
      let leave_taken = leave_planner_data.leave_taken;
      // let leave_types = leave_planner_data.leave_types;

      const public_holidays = leave_planner_data.public_holidays;
      
      setEmployees(leave_planner_data.employees);
      setAccrualRuns(leave_planner_data.accrual_runs);
      setLeaveTaken(leave_taken);
      setLeaveTypes(leave_planner_data.leave_types);
      setLeaveGroups(leave_planner_data.leave_groups);
      setLeaveBalances(leave_planner_data.leave_balances);
      setPublicHolidays(public_holidays);
      setAnnualLeaveEntitlement(leave_planner_data.annual_leave_entitlement);
      setLinkedAccounts(leave_planner_data.linked_accounts);
      setParmCodes(leave_planner_data.cp_parm_codes);
      setPayrollSettings(leave_planner_data.payroll_settings);
      setDBBackups(db_backups);
      setIsLoading(false);
      // setIsInitialLoading(false);

    };
    
    // initialEvents = fetchLeaveValues();
    if (accrualRuns.length === 0){
      fetchLeaveValues();
    } else {
      setIsLoading(false);
      // setIsInitialLoading(false);
    }
  }, []);

  useEffect(() => {

  }, [maintenanceMode]);

  const handleDownloadBackup = async (filename) => {
    setIsLoading(true);
    await downloadBackup(filename);
    setIsLoading(false);
  }

  const handleRestoreBackup = async (filename) => {
    setIsLoading(true);
    await restoreDB(filename);
    setIsLoading(false);
  }

  const handleSQLFileChange = async (event) => {
    // setUploadedBackup(event.target.files[0]);
    setIsLoading(true);
    const result = await restoreDBFromUpload(event.target.files[0]);
    setIsLoading(false);
  };

  const replaceKeys = (obj, mapping) => {
    return Object.keys(obj).reduce((acc, key) => {
      const newKey = mapping[key] || key;
      acc[newKey] = ["yes", "Yes", 1, "1", true, "True", "true"].includes(obj[key]) ? 1 : ["no", "No", 0, "0", false, "False", "false"].includes(obj[key]) ? 0 : obj[key];
        // acc[newKey] = obj[key];
      return acc;
    }, {});
  };

  const convertFloatToTime = (time) => {
    time = parseFloat(time) * 24;
    const integerPart = Math.floor(time);
    const decimalPart = time - integerPart;

    const hour = integerPart.toString().padStart(2, '0');
    const minute = Math.round(decimalPart * 60).toString().padStart(2, '0');

    return `${hour}:${minute}`;
  };


  const handleXLFileUpload = async (e, editMethod, setRows, leave_setting) => {
    setIsLoading(true);
    const file = e.target.files[0];
    const reader = new FileReader();
    let newList = [];

    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      setImportData(jsonData);

      // console.log(jsonData);
      // console.log(leaveTypes);
      
      if (leave_setting === "leave_balances"){
        const keyMapping = {}
  
        Object.entries(jsonData[0]).forEach(([key, value]) => {
          keyMapping[key] = leaveTypes.find(item => item.name === key)?.id || "employee_number";
          // keyMapping[key] = leaveTypes.find(item => item.name === key)?.id || "id";
        });
  
        // console.log(keyMapping);
  
        newList = jsonData.map(item => replaceKeys(item, keyMapping));
  
        // console.log("1", newList);

        newList = newList.map(item => ({
            ...item,
            balances: leaveBalances.find(balance => balance.employee_number === item.employee_number)?.balances,
            // balances:  leaveBalances.reduce((acc, balance) => {
            //   acc[balance.leave_type_id] = item[balance.leave_type_id];
            //   return acc;
            // }, {})
          }
        ));

      } else if (leave_setting === "leave_groups"){
        const keyMapping = {}
  
        Object.entries(jsonData[0]).forEach(([key, value]) => {
          keyMapping[key] = leaveTypes.find(item => item.name === key)?.id || "id";
        });
  
        // console.log(keyMapping);
  
        newList = jsonData.map(item => replaceKeys(item, keyMapping));
  
        // console.log("1", newList);
      } else if (leave_setting === "leave_types") {        
        let keyMapping = {}

        Object.entries(jsonData[0]).forEach(([key, value]) => {
          keyMapping[key] = key.toLowerCase().replace(/ /g, "_");
        });

        keyMapping = {
          ...keyMapping,
          "Leave Type": "name",
          "Approval Type": "leave_approvers_type"
        }
  
        newList = jsonData.map(item => ({ 
          ...item, 
          public_holidays: publicHolidays.reduce((acc, holiday) => {
            acc[holiday.name] = item[holiday.name];
            return acc;
          }, {}),
        }));

        // console.log("0", newList);


        // console.log(keyMapping);        
        
        newList = newList.map(item => replaceKeys(item, keyMapping));

        // console.log("1.5", newList);

        newList = newList.map(item => {
          item.start_time = convertFloatToTime(item.start_time);
          item.end_time = convertFloatToTime(item.end_time);
          return item;
        });

        // console.log("1", newList);
      } else if (leave_setting === "annual_leave_entitlement"){
        const keyMapping = {
          "EmpNo": "employee_number",
          "Days": "days"
        }
  
        // Object.entries(jsonData[0]).forEach(([key, value]) => {
        //   keyMapping[key] = key.toLowerCase().replace(/ /g, "_");
        // });
  
        // console.log(keyMapping);
  
        newList = jsonData.map(item => replaceKeys(item, keyMapping));
  
        // console.log("1", newList);
      }
    };

    reader.readAsArrayBuffer(file);
    // Wait for the onload event to finish
    await new Promise(resolve => {
      reader.onloadend = resolve;
    });

    const uploadedList = {
      editor_employee_number: employee.employee_number,
      rows: newList
    }
    // console.log("2", uploadedList);

    const response = await editMethod(uploadedList);
    setRows(response.data.data);
    // console.log(response);

    setSnackbar({ children: response.data.message, severity: 'success' });
    setIsLoading(false);
  };

  const handleUploadClick = async (variables) => {
    const fileInput = document.getElementById(`${variables["upload_type"]}-file-input`);
    fileInput.value = null; // Reset the value of the file input

    if (variables["upload_type"] === "xl"){
      fileInput.onchange = (event) => {
        handleXLFileUpload(event, variables["editMethod"], variables["setRows"], variables["leave_setting"]);
      };

    }

    fileInput.click(); // Open the file picker dialog
  };

  const handleToggleMaintenanceMode = async () => {
    setIsLoading(true);

    const result = await toggleMaintenanceMode();
    if (result.status === 200){
      setMaintenanceMode(result.data.maintenance_mode);
      setSnackbar({ children: result.data.message, severity: 'success' });
    } else{
      setSnackbar({ children: "Error occurred.", severity: 'error' });
    }

    setIsLoading(false);
  };

  
  //LEAVE TYPES
  let leaveTypesColumns = [
    { field: 'id', headerName: 'Id', width: 20 },
    { field: 'name', headerName: 'Leave Type', width: 300, editable: true },
    {
      field: 'start_time',
      headerName: 'Start Time',
      width: 100,
      type: 'singleSelect',
      valueOptions: Array.from({ length: 48 }, (_, index) => {
        const hour = Math.floor(index / 2) + 0;
        // const hour = Math.floor(index / 2) + 8;
        const minutes = index % 2 === 0 ? '00' : '30';
        return `${hour.toString().padStart(2, '0')}:${minutes}`;
      }),
      editable: true,
    },
    {
      field: 'end_time',
      headerName: 'End Time',
      width: 100,
      type: 'singleSelect',
      valueOptions: Array.from({ length: 48 }, (_, index) => {
        const hour = Math.floor(index / 2) + 0;
        const minutes = index % 2 === 0 ? '00' : '30';
        return `${hour.toString().padStart(2, '0')}:${minutes}`;
      }),
      editable: true,
    },
    { field: 'leave_approvers', headerName: 'Leave Approvers', width: 120, editable: true,
      renderHeader: () => (
        <div style={{ textAlign: 'center', lineHeight: 'normal' }}>
          Leave Approvers
          <br />
          (Comma Seperated)
        </div>
      ),
      valueGetter: (params) => {
        const value = params.value.replace("[", "").replace("]", "");
        return value;
      },
      valueSetter: (params) => {
        const value = `[${params.value}]`;
        return { ...params.row, leave_approvers: value };
      },
    },   
    // { field: 'select_approvers', headerName: 'Select', width: 100, type: 'singleSelect', editable: true, default: "",
    //   valueOptions: [""].concat(employees.map(employee => (employee.employee_number).toString())),
    //   valueSetter: (params) => {
    //     const value = params.value === "" ? `[${params.row.leave_approvers}]` : `[${params.row.leave_approvers},${params.value}]`;
    //     return { ...params.row, leave_approvers: value };
    //   },
    // },
    { field: 'leave_approvers_type', headerName: 'Approval Type', width: 100, editable: true },   
    {
      field: 'accrual_type',
      headerName: 'Accrual Type',
      width: 120,
      type: 'singleSelect',
      valueOptions: Array.from({ length: 48 }, (_, index) => (index + 1)/4),
      editable: true,
      valueFormatter: (params) => {
        return ((params.value) ? ((params.value === 0.25) ? `weekly` : ((params.value === 0.5) ? `fortnightly` : ((params.value === 0.75) ? `3 weeks` : ((params.value === 1) ? `monthly` : `${params.value} months`)))) : '');
      },
    },
    { field: 'accrual_days', headerName: 'Accrual Days', width: 80, editable: true,
      valueGetter: (params) => {
        return (params.row.name === "ANNUAL") ? "Annual LE" : params.value;
      },
      valueSetter: (params) => {
        const value = (params.row.id === 1) ? 0 : params.value;
        return { ...params.row, accrual_days: value };
      },
      // valueFormatter: (params) => {
      //   return (params.value ? `${params.value} days` : '');
      // },
      // valueParser: (value) => {
      //   return value ? parseInt(value.replace(' days', '')) : null;
      // }
     },   
  ];

  const daysColumns = [
    { field: 'monday', headerName: 'Monday', width: 100, type: 'boolean', editable: true },  
    { field: 'tuesday', headerName: 'Tuesday', width: 100, type: 'boolean', editable: true },  
    { field: 'wednesday', headerName: 'Wednesday', width: 100, type: 'boolean', editable: true },  
    { field: 'thursday', headerName: 'Thursday', width: 100, type: 'boolean', editable: true },  
    { field: 'friday', headerName: 'Friday', width: 100, type: 'boolean', editable: true },  
    { field: 'saturday', headerName: 'Saturday', width: 100, type: 'boolean', editable: true },  
    { field: 'sunday', headerName: 'Sunday', width: 100, type: 'boolean', editable: true } 
  ];

  const publicHolidaysNamesColumns = publicHolidays.map(holiday => ({ field: holiday.name, headerName: holiday.name, width: 100, type: 'boolean', editable: true }) );
  
  leaveTypesColumns = leaveTypesColumns.concat(daysColumns);
  leaveTypesColumns = leaveTypesColumns.concat(publicHolidaysNamesColumns);
  
  const addLeaveTypesDictPart = { name: "", accrual_type: "", accrual_days: "", start_time: "", end_time: "",
                              leave_approvers: "", leave_approvers_type: "", 
                              monday: 1, tuesday: 1, wednesday: 1, thursday: 1, friday: 1, saturday: 0, sunday: 0 };

  const addPublicHolidaysDictPart = publicHolidays.reduce((acc, holiday) => { 
    acc[holiday.name] = 0;
    return acc;
  }, {});
                              
  const addLeaveTypesDict = { 
    ...addLeaveTypesDictPart,
    ...addPublicHolidaysDictPart
  };

  let leave_type_ids = [];
  leaveTypes.forEach((leave_type) => { leave_type_ids.push(leave_type["id"]); });

  //LEAVE GROUPS
  const leaveGroupsColumns = [{ field: 'id', headerName: 'Group', width: 20 }].concat(
    leave_type_ids.map((leave_type_id) => { 
      return { 
        field: `${leave_type_id}`, 
        headerName: `${leaveTypes.find(item => item.id === leave_type_id)?.name}`, 
        width: 100, type: 'boolean', editable: true }; }));

  const addLeaveGroupsDict = { };
  leaveTypes.forEach((leave_type) => { addLeaveGroupsDict[leave_type["id"]] = ""; });

  //LEAVE BALANCES
  const leaveBalancesColumns = [
    // { field: 'id', headerName: 'Id', width: 20 },
    { field: 'employee_number', headerName: 'EmpNo', width: 100, editable: true },
    { field: 'name', headerName: 'Name', width: 150, 
      valueGetter: (params) => {
        const emp_data = employees.find(emp => emp.employee_number === params.row.employee_number);
        const full_name = emp_data.name + " " + emp_data.surname;
        return full_name;
      }
     },
  ].concat(
    leave_type_ids.map((leave_type_id) => { return { 
      field: `${leave_type_id}`, 
      headerName: `${leaveTypes.find(item => item.id === leave_type_id)?.name}`, 
      width: 100, 
      type: "float",
      valueGetter: (params) => parseFloat(params.row.balances[`${leave_type_id}`]["available"]).toFixed(2), 
      editable: true }; }));

  const addLeaveBalancesDict = { "balances": {} };
  leaveTypes.forEach((leave_type) => { addLeaveBalancesDict["balances"][leave_type["id"]] = ""; });

  let leave_group_ids = [];
  leaveGroups.forEach((leave_group) => { leave_group_ids.push(leave_group["id"]); });

  //ANNUAL LEAVE ENTITLEMENT
  const annualLeaveEntitlementColumns = [
    { field: 'employee_number', headerName: 'EmpNo', width: 100 },
    { field: 'name', headerName: 'Name', width: 300,
      valueGetter: (params) => {
        const emp_data = employees.find(emp => emp.employee_number === params.row.employee_number);
        const full_name = emp_data.name + " " + emp_data.surname;
        return full_name;
      }
     },
    { field: 'days', headerName: 'Days', width: 300, type: 'float', editable: true },
  ];

  // const addAnnualLeaveEntitlementDict = { name: "", days: 0 };

  //LINKED ACCOUNTS
  const linkedAccountsColumns = [
    { field: 'employee_number', headerName: 'EmpNo', width: 100, type: 'singleSelect', editable: true, valueOptions: employees.map(employee => (employee.employee_number)) },
    { field: 'name', headerName: 'Name', width: 300,
      valueGetter: (params) => {
        const emp_data = employees.find(emp => emp.employee_number === params.row.employee_number);
        const full_name = emp_data?.name + " " + emp_data?.surname;
        return full_name;
      }
     },
    { field: 'linked_accounts', headerName: 'Linked Accounts', width: 300, type: 'float', editable: true,
      renderHeader: () => (
        <div style={{ textAlign: 'center', lineHeight: 'normal' }}>
          Linked Accounts
          <br />
          (Comma Seperated)
        </div>
      ),
      valueGetter: (params) => {
        const value = params.value.replace("[", "").replace("]", "");
        return value;
      },
      valueSetter: (params) => {
        const value = `[${params.value}]`;
        return { ...params.row, linked_accounts: value };
      },
     },
  ];

  const addLinkedAccountDict = { employee_number: "", linked_accounts: "" };

  //PUBLIC HOLIDAYS
  const publicHolidaysColumns = [
    { field: 'id', headerName: 'Id', width: 20 },
    { field: 'name', headerName: 'Name', width: 300, editable: true },
    { field: 'date', headerName: 'Date', width: 300, type: 'date', editable: true,
      valueGetter: (params) => new Date(params.row.date),
      renderCell: ({ row }) => (
        format(new Date(row.date), 'dd-MM-yyyy')
      )
     },
  ];

  const addPublicHolidaysDict = { name: "", date: new Date() };

  //EMPLOYEES
  const options = [
    { value: 1, label: 'Option 1' },
    { value: 2, label: 'Option 2' },
    { value: 3, label: 'Option 3' },
  ];

  const MultiSelectCell = ({ value, id, field, api }) => {
    const [selectedOptions, setSelectedOptions] = useState(value || []);
  
    const handleChange = (newOptions) => {
      setSelectedOptions(newOptions);
      api.setRowData(id, { ...api.getRow(id), [field]: newOptions }); // Update data
    };
  
    return (
      <Select
        isMulti
        value={selectedOptions}
        options={options}
        onChange={handleChange}
      />
    );
  };

  const employeesColumns = [
    { field: 'employee_number', headerName: 'EmpNo', width: 20 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'surname', headerName: 'Surname', width: 150 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'leave_group_id', headerName: 'Leave Group', width: 100, type: 'singleSelect', valueOptions: leave_group_ids, editable: true},
    { field: 'access_level', headerName: 'Access Level', width: 100, type: 'singleSelect', valueOptions: ["1", "2", "3"], editable: true},
    { field: 'stop_accrual',  headerName: 'Stop Accrual', width: 150, type: 'boolean', editable: true,
      valueGetter: (params) => params.row.employee_information.stop_accrual,
      valueSetter: (params) => {
        const updatedData = {...params.row};
        updatedData.employee_information.stop_accrual = params.value;
        return updatedData; 
      }
    },
  ];  

  //ACCRUAL RUNS
  const accrualRunsColumns = [
    // { field: 'id', headerName: 'Id', width: 20 },
    { field: 'month', headerName: 'Month', width: 300, editable: true },
    { field: 'run_number', headerName: 'Run Number', width: 300, editable: true },
    { field: 'total_runs', headerName: 'Total Runs', width: 300, editable: true },
  ];

  //PAYROLL SETTINGS
  const payrollSettingsColumns = [
    // { field: 'id', headerName: 'Id', width: 20 },
    { field: 'leave_type', headerName: 'Leave Type', width: 300,
      valueGetter: (params) => {
        const leave_type = leaveTypes.find(leave_type => leave_type.id === params.row.leave_type_id);
        return leave_type?.name;
      }
     },
    { field: 'leave_balance_code', headerName: 'Leave Balance Code', width: 300, type: 'singleSelect', editable: true,
      valueOptions: parmCodes.filter(code => code.CodeName).map(code => `${code.OrdinalNo} - ${code.CodeName}`), 
      valueGetter: (params) => {
        if (parmCodes.find(code => parseInt(code.OrdinalNo) === parseInt(params.row.leave_balance_code))){
          return `${params.row.leave_balance_code} - ${parmCodes.find(code => parseInt(code.OrdinalNo) === parseInt(params.row.leave_balance_code))?.CodeName}`
        } else {
          return `${parmCodes[0]?.OrdinalNo} - ${parmCodes[0]?.CodeName}`
        }
      },
      valueSetter: (params) => {
        let [leave_balance_code, codeName] = params.value.split(' - ');
        leave_balance_code = parseInt(leave_balance_code);
        return { ...params.row, leave_balance_code };
      } 
    },
    { field: 'leave_taken_code', headerName: 'Leave Taken Code', width: 300, type: 'singleSelect', editable: true,
      valueOptions: parmCodes.filter(code => code.CodeName).map(code => `${code.OrdinalNo} - ${code.CodeName}`), 
      valueGetter: (params) => {
        if (parmCodes.find(code => parseInt(code.OrdinalNo) === parseInt(params.row.leave_taken_code))){
          return `${params.row.leave_taken_code} - ${parmCodes.find(code => parseInt(code.OrdinalNo) === parseInt(params.row.leave_taken_code))?.CodeName}`
        } else {
          return `${parmCodes[0]?.OrdinalNo} - ${parmCodes[0]?.CodeName}`
        }
      },
      valueSetter: (params) => {
        let [leave_taken_code, codeName] = params.value.split(' - ');
        leave_taken_code = parseInt(leave_taken_code);
        return { ...params.row, leave_taken_code };
      } 
     },
  ];

  //BACKUPS
  const backupsColumns = [
    { field: 'id', headerName: 'Id', width: 20 },
    { field: 'filename', headerName: 'File Name', width: 300, editable: false },
    { field: 'download', headerName: 'Download', width: 100, 
      renderCell: ({ row }) => (
        <Button 
          onClick={() => handleDownloadBackup(row.filename)} 
          type="button" 
          color="secondary" 
          variant="contained"
        >
          Download
        </Button>
      )
    },
    { field: 'restore', headerName: 'Restore', width: 100, 
      renderCell: ({ row }) => (
        <Button 
          onClick={() => handleRestoreBackup(row.filename)} 
          type="button" 
          color="secondary" 
          variant="contained"
        >
          Restore
        </Button>
      )
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <CustomSnackBar />
      <Button 
          onClick={() => handleToggleMaintenanceMode()} 
          type="button" 
          color={maintenanceMode ? "error": "secondary"} 
          // color={maintenanceMode ? "secondary": "error"} 
          variant="contained"
        >
          {`Turn ${maintenanceMode ? "OFF": "ON"} maintenance mode`}
      </Button>

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="LEAVE TYPES"
        expanded={false}
        cardContent={
          <CustomDataGrid 
          rows={leaveTypes} 
          setRows={setLeaveTypes} 
          otherColumns={[leaveTypesColumns, addLeaveTypesDict]} 
          backendCRUD = {{
            "C": editLeaveType,
            "R": getLeaveTypes,
            "U": editLeaveType,
            "D": deleteLeaveRecord,
            "TYPE": "LEAVE TYPE",
          }}
          actions={true}
          extraButtons={[
            {icon: <AddIcon />, label: "Import", func: handleUploadClick, func_variables: {upload_type:"xl", leave_setting: "leave_types"}, func_type: "import excel"},
          ]}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="LEAVE GROUPS"
        expanded={false}
        cardContent={
          <CustomDataGrid 
          rows={leaveGroups} 
          setRows={setLeaveGroups} 
          otherColumns={[leaveGroupsColumns, addLeaveGroupsDict]} 
          backendCRUD = {{
            "C": addLeaveGroup,
            "R": getLeaveGroups,
            "U": editLeaveGroup,
            "D": deleteLeaveRecord,
            "TYPE": "LEAVE GROUP",
          }}
          actions={true}
          extraButtons={[
            {icon: <AddIcon />, label: "Import", func: handleUploadClick, func_variables: {upload_type:"xl", leave_setting: "leave_groups"}, func_type: "import excel"},
          ]}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="LEAVE BALANCES"
        expanded={false}
        cardContent={
          <CustomDataGrid 
          rows={leaveBalances} 
          setRows={setLeaveBalances} 
          otherColumns={[leaveBalancesColumns, addLeaveBalancesDict]} 
          backendCRUD = {{
            "C": addLeaveBalance,
            "R": getLeaveBalances,
            "U": addLeaveBalance,
            "D": deleteLeaveRecord,
            "TYPE": "LEAVE BALANCE",
          }}
          actions={true}
          extraButtons={[
            {icon: <AddIcon />, label: "Import", func: handleUploadClick, func_variables: {upload_type:"xl", leave_setting: "leave_balances"}, func_type: "import excel"},
          ]}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="EMPLOYEES"
        expanded={false}
        cardContent={
          <CustomDataGrid
            rows={employees} 
            // rows={employees.map((employee) => ({...employee, id: [employee.employee_number] }))} 
            setRows={setEmployees} 
            otherColumns={[employeesColumns, ""]} 
            backendCRUD = {{
              "C": false,
              "R": getEmployees,
              "U": editEmployee,
              "D": false,
              "TYPE": false,
          }}
            actions={true}
          />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="ANNUAL LEAVE ENTITLEMENT"
        expanded={false}
        cardContent={
          <CustomDataGrid
            rows={annualLeaveEntitlement} 
            setRows={setAnnualLeaveEntitlement} 
            otherColumns={[annualLeaveEntitlementColumns, {}]} 
            backendCRUD = {{
              "C": null,
              "R": getEmployees,
              "U": editAnnualLeaveEntitlement,
              "D": null,
              "TYPE": "ANNUAL LEAVE ENTITLEMENT",
          }}
            actions={true}
            extraButtons={[
              {icon: <AddIcon />, label: "Import", func: handleUploadClick, func_variables: {upload_type:"xl", leave_setting: "annual_leave_entitlement"}, func_type: "import excel"},
            ]}
          />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="LINKED ACCOUNTS"
        expanded={false}
        cardContent={
          <CustomDataGrid
            rows={linkedAccounts} 
            setRows={setLinkedAccounts} 
            otherColumns={[linkedAccountsColumns, addLinkedAccountDict]} 
            backendCRUD = {{
              "C": editLinkedAccount,
              "R": getEmployees,
              "U": editLinkedAccount,
              "D": deleteLeaveRecord,
              "TYPE": "LINKED ACCOUNTS",
          }}
            actions={true}
            // extraButtons={[
            //   {icon: <AddIcon />, label: "Import", func: handleUploadClick, func_variables: {upload_type:"xl", leave_setting: "annual_leave_entitlement"}, func_type: "import excel"},
            // ]}
          />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="PUBLIC HOLIDAYS"
        expanded={false}
        cardContent={
          <CustomDataGrid
            rows={publicHolidays} 
            setRows={setPublicHolidays} 
            otherColumns={[publicHolidaysColumns, addPublicHolidaysDict]} 
            backendCRUD = {{
              "C": addPublicHoliday,
              "R": getEmployees,
              "U": editPublicHoliday,
              "D": deleteLeaveRecord,
              "TYPE": "PUBLIC HOLIDAY",
          }}
            actions={true}
          />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="ACCRUAL RUNS"
        expanded={false}
        cardContent={
          <CustomDataGrid 
          rows={accrualRuns} 
          setRows={setAccrualRuns} 
          otherColumns={[accrualRunsColumns, []]} 
          backendCRUD = {{
            "C": null,
            "R": getAccrualRuns,
            "U": runAccrualRun,
            "D": null,
            "TYPE": "ACCRUAL RUNS",
          }}
          actions={false}
          extraButtons={[
            {icon: <AddIcon />, label: "Run", func:runAccrualRun, func_variables: {"action":"run"}, func_type: "accrual_run"},
            {icon: <RemoveIcon />, label: "Undo Last Accrual", func:runAccrualRun, func_variables: {"action":"undo"}, func_type: "accrual_run"}
          ]}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="PAYROLL SETTINGS"
        expanded={false}
        cardContent={
          <CustomDataGrid 
          rows={payrollSettings} 
          setRows={setPayrollSettings} 
          otherColumns={[payrollSettingsColumns, []]} 
          backendCRUD = {{
            "C": editPayrollSetting,
            "R": null,
            "U": editPayrollSetting,
            "D": null,
            "TYPE": "PAYROLL SETTINGS",
          }}
          actions={false}
          extraButtons={[
            // {icon: <AddIcon />, label: "Run", func:runAccrualRun, func_variables: {"action":"run"}, func_type: "accrual_run"},
          ]}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <input id="sql-file-input" type="file" accept=".sql" style={{ display: 'none' }} onChange={handleSQLFileChange} />
      <input id="xl-file-input" type="file" accept=".xlsx" style={{ display: 'none' }} /> 
      {/* onChange={handleXLFileUpload} /> */}

      <CollapsableCard 
        cardHeader="BACKUPS"
        expanded={false}
        cardContent={
          <>
            <CustomDataGrid
                rows={dbBackups}
                setRows={setDBBackups}
                otherColumns={[backupsColumns, []]}
                backendCRUD={{
                  "C": null,
                  "R": getAvailableBackups,
                  "U": null,
                  "D": null,
                  "TYPE": "BACKUP",
                }}
                actions={false}
                extraButtons={[
                  { icon: <Save />, label: "Backup", func: backupDB, func_variables: {}, func_type: "backup" },
                  { icon: <AddIcon />, label: "Restore", func:() => handleUploadClick({"upload_type": "sql"}), func_variables: {}, func_type: "restore" },
                ]} 
              />
          </>
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

    </Box>
  );  
};

export default LeaveSettingsPage;