import React, { useState, useEffect } from 'react';
import { TextField, Button, Checkbox, FormControlLabel } from '@mui/material';
import { getSetting, setSetting } from '../../services/settingsService';
import { forgot_password } from '../../services/employeeService';
import NamedDivider from '../../components/NamedDivider';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useStateContext } from '../../contexts/ContextProvider';
import LoadingOverlay from '../global/LoadingOverlay';


function SettingsPage() {
  const { employee, snackbar, setSnackbar, isLoading, setIsLoading } = useStateContext(); 
  // const [config, setConfig] = useState({});
  const [config, setConfig] = useState({
    min_length: 8,
    include_numbers: false,
    include_special_chars: false,
    include_uppercase: false,
  });

  useEffect(() => {
    async function fetchConfig() {
      try {
        const response = await getSetting("password-complexity");
        setConfig(response.data);
      } catch (error) {
        console.error('Error fetching configuration:', error);
      }
    }
    fetchConfig();
  }, []);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setConfig((prevConfig) => ({
      ...prevConfig,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handlePasswordComplexitySubmit = async () => {
    setIsLoading(true);

    try {
      // Replace 'your-api-endpoint' with the actual endpoint URL
      const response = await setSetting( "password-complexity", config);

      if(response.status === 200){
        setSnackbar({ children: 'Configuration saved', severity: 'success' });
        // console.log('Configuration saved:', response.data);
      } else {
        setSnackbar({ children: 'Failed to save configuration', severity: 'error' });
      }
    } catch (error) {
      setSnackbar({ children: error, severity: 'error' });
      // console.error('Error saving configuration:', error);
    }

    setIsLoading(false);
  };

  const handlePasswordResetClick = async () => {
    setIsLoading(true);
    
    try {
      const response = await forgot_password(employee);

      if (response.status === 200) {
        setSnackbar({ children: 'Password reset email sent', severity: 'success' });
      } else {
        setSnackbar({ children: 'Failed to reset', severity: 'error' });
      }
    } catch (error) {
      console.error('Error saving configuration:', error);
      setSnackbar({ children: error, severity: 'error' });
    }

    setIsLoading(false);
  }

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <div style={{ maxWidth: '800px', margin: 'auto' }}>
      {![3, "3"].includes(employee.access_level) &&
        <>
          <TextField
            fullWidth
            label="Minimum Length"
            name="min_length"
            type="number"
            value={config.min_length}
            onChange={handleChange}
            margin="normal"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!config.include_numbers}
                onChange={handleChange}
                name="include_numbers"
              />
            }
            label="Include Numbers"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!config.include_special_chars}
                onChange={handleChange}
                name="include_special_chars"
              />
            }
            label="Include Special Characters"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!config.include_uppercase}
                onChange={handleChange}
                name="include_uppercase"
              />
            }
            label="Include Uppercase"
          />
          <Button variant="contained" onClick={handlePasswordComplexitySubmit} sx={{ mt: 2 }}>
            Save Configuration
          </Button>
        </>
      }

      <NamedDivider text="Reset Password" />

      <section>
        <Button variant="contained" onClick={handlePasswordResetClick} sx={{ mt: 2 }}>
          Reset Password
        </Button>
      </section>

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}   

      <LoadingOverlay isLoading={isLoading} />
    </div>
  );
}

export default SettingsPage;
