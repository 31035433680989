import React from 'react';
import '../../styles/chrLoading.css';

function LoadingOverlay({ isLoading }) {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="loading-backdrop">
      <div className="loading-icon"></div>
    </div>
  );
}

export default LoadingOverlay;