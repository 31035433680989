import React from 'react';
import '../styles/styles.css';

const CircleNumber = ({ number, color}) => {
  return (
    <div className = {`circle-number-${color}`}>
      {number}
    </div>
  );
};

export default CircleNumber;