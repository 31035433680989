import React, { createContext, useContext, useState, useEffect } from 'react';

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [employee, setEmployee] = useState(null);
  const [beforeLoginMessage, setBeforeLoginMessage] = useState(null);
  const [loggedOut, setLoggedOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [backdropOpen, setBackdropOpen] = useState(false);

  const [employees, setEmployees] = useState([]);
  const [allLeaveTaken, setAllLeaveTaken] = useState([]);
  const [approveeLeaveTaken, setApproveeLeaveTaken] = useState([]);
  const [leaveTaken, setLeaveTaken] = useState([]);
  const [leaveBalances, setLeaveBalances] = useState([]);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [selectedEditLeave, setSelectedEditLeave] = useState(null);
  const [selectedLeaveType, setSelectedLeaveType] = useState(1);
  const [leaveGroups, setLeaveGroups] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [publicHolidays, setPublicHolidays] = useState([]);
  const [annualLeaveEntitlement, setAnnualLeaveEntitlement] = useState([]);
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const [parmCodes, setParmCodes] = useState([]);
  const [payrollSettings, setPayrollSettings] = useState([]);
  const [accrualRuns, setAccrualRuns] = useState([]);
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState('#03C9D7');
  const [currentMode, setCurrentMode] = useState('Light');
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);

  const [currentEvents, setCurrentEvents] = useState([]);
  const [openLeaveApplicationPopup, setOpenLeaveApplicationPopup] = useState(false);
  const [openFailedPopup, setOpenFailedPopup] = useState(false);
  const [openCalendarPopup, setOpenCalendarPopup] = useState(false);
  const [openDeleteEventPopup, setOpenDeleteEventPopup] = useState(false);

  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [inputUploadType, setInputUploadType] = useState("SQL")
  
  const [duration, setDuration] = useState(0);
  const [resultMessage, setResultMessage] = useState("");
  // date state
  const [range, setRange] = useState([
    {
      startDate: new Date(2023, 11, 1, 12, 17, 49),
      endDate: new Date(2023, 11, 1, 12, 17, 49),
      key: 'selection',
      manual_duration_enabled:false, 
      leave_type_times: { start_time: "", end_time: "" }, 
      selected_times: { start_time: "", end_time: "" } 
    }
  ]);

  const [dbBackups, setDBBackups] = useState([]);


  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem('themeMode', e.target.value);
    setThemeSettings(false);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem('colorMode', color);
    setThemeSettings(false);
  };

  const handleClick = (clicked) => setIsClicked({ ...initialState, [clicked]: true });

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if( e.key === "Escape" ) {
      setOpenLeaveApplicationPopup(false)
    }
  }

  // Hide on outside click
  const hideOnClickOutside = (ref) => (e) => {
    // console.log(ref.current)
    // console.log(e.target)
    if( ref.current && ! ref.current.contains(e.target) ) {
      setOpenLeaveApplicationPopup(false)
    }
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider 
        value={{ token, setToken, employee, setEmployee, beforeLoginMessage, setBeforeLoginMessage, 
                leaveBalances, setLeaveBalances, employees, setEmployees, loggedOut, setLoggedOut,
                isLoading, setIsLoading, snackbar, setSnackbar, backdropOpen, setBackdropOpen,
                currentColor, currentMode, activeMenu, screenSize, allLeaveTaken, setAllLeaveTaken,
                approveeLeaveTaken, setApproveeLeaveTaken, leaveTaken, setLeaveTaken, leaveTypes, setLeaveTypes,
                selectedLeaveType, setSelectedLeaveType, leaveGroups, setLeaveGroups, publicHolidays, setPublicHolidays,
                selectedLeave, setSelectedLeave, selectedEditLeave, setSelectedEditLeave, annualLeaveEntitlement, setAnnualLeaveEntitlement,
                linkedAccounts, setLinkedAccounts, parmCodes, setParmCodes, payrollSettings, setPayrollSettings,
                setScreenSize, handleClick, isClicked, initialState, setIsClicked, setActiveMenu, setCurrentColor, setCurrentMode, 
                setMode, setColor, themeSettings, setThemeSettings,
                currentEvents, setCurrentEvents, openFailedPopup, setOpenFailedPopup, 
                openLeaveApplicationPopup, setOpenLeaveApplicationPopup, accrualRuns, setAccrualRuns,
                openCalendarPopup, setOpenCalendarPopup, openDeleteEventPopup, setOpenDeleteEventPopup, 
                hideOnEscape, hideOnClickOutside, range, setRange, 
                duration, setDuration, resultMessage, setResultMessage,
                dbBackups, setDBBackups, maintenanceMode, setMaintenanceMode,
                inputUploadType, setInputUploadType }} //manualDuration, setManualDuration,
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);