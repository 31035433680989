import React from 'react';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { state, useState, useEffect } from 'react';
import { Formik } from "formik";
import * as yup from "yup";
import {
  // Container,
  Typography,
  TextField,
  Button,
  Grid,
} from '@mui/material';

import { useStateContext } from "../../contexts/ContextProvider";
import { login } from '../../services/employeeService';
import { getLeaveBalances } from '../../services/leaveService';
import LoadingOverlay from '../global/LoadingOverlay';

function LoginPage() {
  const navigate = useNavigate();

  const { setToken, isLoading, setIsLoading, setEmployee, setLeaveBalances, beforeLoginMessage, setBeforeLoginMessage } = useStateContext(); 

  // useEffect(() => {
  //   const fetchLeaveBalances = async () => {
  //     const balances = await getLeaveBalances(employee);
  //     setLeaveBalances(balances);
  //   };

  //   fetchLeaveBalances();
  // }, [employee]);

  const _setToken = (token) => {
    setToken(token);
    localStorage.setItem("token", token);
  }

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    // e.preventDefault();
    try {
      // let employee = await register(values);
      let result = await login(values);
      let status = result.status;
      let message = result.data.message;

      // console.log("LOGIN", result);

      // console.log("STATUS", status);
      // console.log("MESSAGE", message);
      if (status !== 200) {
        setBeforeLoginMessage(message);
      }
      else {
        //pop up message at the top for successful registration
        setEmployee(result.data.employee);
        _setToken(result.data.token);
        // window.location.reload();
        navigate("/leave-application");
        // <Navigate to="/login" replace={true} />
        setBeforeLoginMessage(null);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error", error)
    }
  };

  return (
    <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    sx={{ minHeight: '100vh' }}
    >
      {/* {registrationError && (
        <Navigate to="/login" replace={true} />
      )} */}
      <LoadingOverlay isLoading={isLoading} />
      
      <Grid item xs={12} sm={6} md={4}>
        <Typography variant="h4" component="h1" sx={{marginBottom: "20px",}}>
          {beforeLoginMessage && <p>{beforeLoginMessage}</p>}
          Login
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form 
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: "2px",
              }} 
              onSubmit={handleSubmit}
            >
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Email"
                  type="email"
                  name = "email"
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} sx={{marginTop: "20px",}}>
                <TextField
                  label="Password"
                  type="password"
                  name = "password"
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{marginTop: "10px",}}
                >
                  Sign In
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={12} sx={{marginTop: "10px",}}>
                <Typography variant="body2">
                  First time logging in?{' '}
                  <Link to="forgot-password">Set password</Link>
                </Typography>
                {/* <Typography variant="body2">
                  Don't have an account?{' '}
                  <Link to="register">Sign Up</Link>
                </Typography> */}
                <Typography variant="body2">
                  {/* <Link to="reset_password">Forgot password</Link> */}
                  <Link to="forgot-password">Forgot password</Link>
                </Typography>
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  // leave_type: yup.string().required("required"),
  // reason: yup.string().required("required"),
  // attatchment: yup.string().required("required"),
  // email: yup.string().email("invalid email").required("required"),
  // contact: yup
  //   .string()
  //   .matches(phoneRegExp, "Phone number is not valid")
  //   .required("required"),
  // address1: yup.string().required("required"),
  // address2: yup.string().required("required"),
});

const initialValues = {
  name: "",
  email: "",
  password: ""
};

export default LoginPage;