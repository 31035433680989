import { useState, useEffect, useCallback } from 'react';

import { useStateContext } from '../contexts/ContextProvider';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';

function CustomSnackBar() {
  const { snackbar, setSnackbar, backdropOpen, setBackdropOpen } = useStateContext(); 
  
  const handleCloseSnackbar = () => {
    setSnackbar(null);
    setBackdropOpen(false);
  }
  
  if (!snackbar) {
    return null;
  }
  
  return (
    <div>
      {!!snackbar && (
        <div>
          <Snackbar
            open
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
          <Backdrop
            sx={{ 
              color: '#fff', 
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: 'rgba(0, 0, 0, 0.0)'  // Fully transparent background
            }}
            open={backdropOpen}
            onClick={() => {
              setSnackbar(null);
              setBackdropOpen(false);
            }} // Close on backdrop click if desired
          >
            {/* Optionally, add additional UI or content over the backdrop */}
          </Backdrop>
        </div>
      )}   
    </div>
  );  
};


export default CustomSnackBar;