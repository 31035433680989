import { ApiService, setCsrfAndTokenHeader, setBlobForFileDownload, unsetBlobForFileDownload } from "./axiosConfig";

export const getAvailableBackups = async () => {
  try {
      await setCsrfAndTokenHeader("get")
      let response = await ApiService.get('/db-backups');

      // console.log("getAvailableBackups", response);

      return response.data
  } catch (err) {
      // console.log(err);
      console.log("somthing Wrong logout");
  }
};

export const downloadBackup = async ( filename ) => {
  try {
    // console.log("FILENAME", filename);

    await setCsrfAndTokenHeader("get");
    setBlobForFileDownload();

    const response = await ApiService.get('/db-backups/download/'+filename);

    // console.log("FILE RESPONSE", response);

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();

    unsetBlobForFileDownload();
    
    // return response

  } catch (error) {
    console.error("ERROR TRY", error);
    return error.response
  }
};

export const backupDB = async () => {
  try {
      await setCsrfAndTokenHeader("post")
      let response = await ApiService.post('/backup-database', null);

      console.log("backupDB", response);

      return response
  } catch (err) {
      console.log(err);
      console.log("somthing Wrong backup");
  }
};

export const restoreDB = async ( filename) => {
  console.log(filename);
  try {
      await setCsrfAndTokenHeader("post")
      let response = await ApiService.post('/restore-database', {"filename": filename});

      console.log("restoreDB", response);

      return response
  } catch (err) {
      console.log(err);
      console.log("somthing Wrong restore");
  }
};

export const restoreDBFromUpload = async ( file ) => {
  try {
      await setCsrfAndTokenHeader("post")

      const formData = new FormData();
      formData.append('uploaded_backup', file);

      let response = await ApiService.post('/restore-uploaded-database', formData);

      console.log("restoreDBFromUpload", response);

      return response
  } catch (err) {
      console.log(err);
      console.log("somthing Wrong restore from upload");
  }
};